import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "evobike" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "evobike-elcykel---upptäck-vår-kollektion"
    }}>{`EvoBike Elcykel - Upptäck Vår Kollektion`}</h1>
    <p>{`Välkommen till vår dedikerade sida för EvoBikes elcyklar! EvoBike erbjuder en bred och högkvalitativ samling av elcyklar som är designade för att passa dina behov, oavsett om du pendlar dagligen, utforskar nya vägar eller transporterar familjen. Här nedan presenterar vi de olika serierna av EvoBikes och deras främsta egenskaper.`}</p>
    <h2 {...{
      "id": "evobike-serien"
    }}>{`EvoBike Serien`}</h2>
    <h3 {...{
      "id": "evobike-sport-9"
    }}>{`EvoBike SPORT-9`}</h3>
    <p>{`EvoBike SPORT-9-serien är byggd för den moderna pendlaren som värdesätter kraft och stil. Dessa elcyklar är utrustade med en mittmotor på 250W och erbjuder ett batteri på 432 Wh eller 540 Wh, vilket ger en imponerande räckvidd på upp till 90 km. För både dam- och herrmodeller, är SPORT-9 idealisk för stadsäventyr och bekväma pendlingar. Med nio växlar och robusta aluminiumramar får du en smidig körupplevelse på varierad terräng.`}</p>
    <h4 {...{
      "id": "nyckelfunktioner"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`Kraftfull 250W mittmotor`}</li>
      <li parentName="ul">{`Batterikapacitet på 432 Wh eller 540 Wh`}</li>
      <li parentName="ul">{`Räckvidd upp till 90 km`}</li>
      <li parentName="ul">{`Nio växlar från Shimano Alivio`}</li>
      <li parentName="ul">{`Praktisk LCD-display för körstatistik`}</li>
    </ul>
    <h3 {...{
      "id": "evobike-sport-8"
    }}>{`EvoBike SPORT-8`}</h3>
    <p>{`SPORT-8-serien erbjuder en högpresterande cykelupplevelse med sin bakhjulsmotor från Ananda och Shimano Acera växelsystem. Med en räckvidd på 45-85 km, beroende på batterival (432 Wh eller 504 Wh), är denna serie perfekt för både stadscykling och fritid. Den robusta aluminiumramen och OLED-displayen gör SPORT-8 till ett utmärkt val för dem som söker en funktionell och stilren elcykel.`}</p>
    <h4 {...{
      "id": "nyckelfunktioner-1"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`Bakhjulsmotor från Ananda`}</li>
      <li parentName="ul">{`Batterikapacitet på 432 Wh eller 504 Wh`}</li>
      <li parentName="ul">{`Räckvidd upp till 85 km`}</li>
      <li parentName="ul">{`Shimano Acera 8-växlad växellåda`}</li>
      <li parentName="ul">{`OLED-display och hydrauliska skivbromsar`}</li>
    </ul>
    <h3 {...{
      "id": "evobike-travel-hopfällbar"
    }}>{`EvoBike Travel (Hopfällbar)`}</h3>
    <p>{`EvoBike Travel-serien är den perfekta hopfällbara elcykeln för dig som söker portabilitet och prestanda. Med en lätt och robust aluminiumram samt en kraftfull navmotor från Ananda, ger denna serie en smidig åktur med en räckvidd på upp till 60 km. Travel är idealisk för både daglig pendling och längre utflykter.`}</p>
    <h4 {...{
      "id": "nyckelfunktioner-2"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`Hopfällbar design med aluminiumram`}</li>
      <li parentName="ul">{`Bakhjulsmotor från Ananda`}</li>
      <li parentName="ul">{`Batterikapacitet på 324 Wh eller 378 Wh`}</li>
      <li parentName="ul">{`Räckvidd upp till 60 km`}</li>
      <li parentName="ul">{`Innovativ OLED-display`}</li>
    </ul>
    <h3 {...{
      "id": "evobike-cargo"
    }}>{`EvoBike Cargo`}</h3>
    <p>{`EvoBike Cargo-serien är designad för småbarnsfamiljer och dem som behöver extra lastkapacitet. Med kraftfulla motorer från Bafang och robusta konstruktioner, erbjuder Cargo-serien både last- och passagerarkapacitet med hög komfort och säkerhet. Utrustade med hydrauliska skivbromsar och Shimano-växlar kan du vara säker på en pålitlig resa varje gång.`}</p>
    <h4 {...{
      "id": "nyckelfunktioner-3"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`Kraftfull Bafang-motor`}</li>
      <li parentName="ul">{`Last- och passagerarkapacitet upp till 160 kg`}</li>
      <li parentName="ul">{`Hydrauliska skivbromsar och LED-belysning`}</li>
      <li parentName="ul">{`Räckvidd upp till 60 km beroende på modell`}</li>
      <li parentName="ul">{`Praktiska säkerhetsfunktioner för familjeanvändning`}</li>
    </ul>
    <h3 {...{
      "id": "evobike-classic-7"
    }}>{`EvoBike Classic-7`}</h3>
    <p>{`Classic-7-serien kombinerar klassisk stil med modern teknik. Denna serie är utrustad med en pålitlig Ananda framhjulsmotor och Shimano Nexus 7 växlar, vilket ger en smidig och bekväm körning. Med en räckvidd upp till 100 km tack vare val mellan batteristorlekar på 432 Wh eller 576 Wh, är Classic-7 perfekt för både dagliga ärenden och längre utflykter.`}</p>
    <h4 {...{
      "id": "nyckelfunktioner-4"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`Ananda framhjulsmotor`}</li>
      <li parentName="ul">{`Shimano Nexus 7 växlar`}</li>
      <li parentName="ul">{`Batterikapacitet på 432 Wh eller 576 Wh`}</li>
      <li parentName="ul">{`Räckvidd upp till 100 km`}</li>
      <li parentName="ul">{`Hydrauliska skivbromsar och fotbroms`}</li>
    </ul>
    <h3 {...{
      "id": "evobike-flex"
    }}>{`EvoBike Flex`}</h3>
    <p>{`Flex-serien erbjuder en innovativ elektrisk trehjuling designad för maximal stabilitet och komfort. Med en kraftfull 250W elmotor och ett batteri på 468 Wh, ger Flex en räckvidd på upp till 70 km. Perfekt för både stadsmiljö och landsvägsturer, denna trehjuling erbjuder enastående stabilitet och dynamik.`}</p>
    <h4 {...{
      "id": "nyckelfunktioner-5"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`Elektrisk trehjuling med 250W elmotor`}</li>
      <li parentName="ul">{`Batterikapacitet på 468 Wh`}</li>
      <li parentName="ul">{`Räckvidd upp till 70 km`}</li>
      <li parentName="ul">{`Låg instegshöjd och praktiska korgar`}</li>
    </ul>
    <h2 {...{
      "id": "köpguiden---vilken-evobike-serie-passar-dig"
    }}>{`Köpguiden - Vilken EvoBike Serie Passar Dig?`}</h2>
    <h3 {...{
      "id": "för-pendlaren"
    }}>{`För Pendlaren:`}</h3>
    <p>{`Om du pendlar dagligen och söker en pålitlig och högpresterande elcykel, är både EvoBike SPORT-9 och SPORT-8 utmärkta val. SPORT-9 ger en kraftfull mittmotor med längre räckvidd, medan SPORT-8 erbjuder bakhjulsmotor och smidig växling för varierad terräng.`}</p>
    <h3 {...{
      "id": "för-äventyrare"
    }}>{`För Äventyrare:`}</h3>
    <p>{`EvoBike Travel är den idealiska hopfällbara elcykeln för utflykter och dagliga äventyr. Dess portabilitet och imponerande räckvidd gör den perfekt för både arbete och fritid.`}</p>
    <h3 {...{
      "id": "för-familjen"
    }}>{`För Familjen:`}</h3>
    <p>{`EvoBike Cargo-serien, inklusive modeller som Cargo Duo och Cargo Urban, erbjuder säkerhet och komfort för hela familjen. Dessa modeller är utmärkta för att transportera barn eller last.`}</p>
    <h3 {...{
      "id": "för-klassisk-stil"
    }}>{`För Klassisk Stil:`}</h3>
    <p>{`EvoBike Classic-7 kombinerar tidlös design med moderna tekniska funktioner, perfekt för dem som värdesätter estetik och funktionalitet i ett.`}</p>
    <h3 {...{
      "id": "för-stabilitet-och-komfort"
    }}>{`För Stabilitet och Komfort:`}</h3>
    <p>{`EvoBike Flex och EvoBike Elegant är perfekta för dem som söker stabilitet och hög lastkapacitet. Dessa trehjuliga elcyklar erbjuder säker och bekväm körning med gott om utrymme för dina dagliga ärenden.`}</p>
    <p>{`Utforska vår EvoBike-kollektion och hitta den elcykel som bäst matchar dina behov. Oavsett vilken modell du väljer, kan du vara säker på att du får en högkvalitativ och pålitlig elcykel som gör din färd både roligare och enklare.`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      